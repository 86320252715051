<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div :class="['dash_card_body',{'pb-0':giftcardConfig.approval_required}]">
                <div class="header_section">
                  <h4 class="menu_header">Gift Cards</h4>
                  <div :class="{'disabled_content': user.role.id == 3}">
                    <router-link :to="{ name: 'bulkgenerate' }">
                      <button class="btn btn-default custom-orange">
                        <i class="fa fa-upload"></i> Bulk Generate
                      </button>
                    </router-link>
                  </div>
                </div>
                <div :class="['search_section',{'mb-0':giftcardConfig.approval_required}]">
                  <div class="row">
                    <div class="col-md-6">
                      <input aria-invalid="false" v-model="search" @keyup="getGiftVouchers()" aria-required="false"
                        autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." type="search"
                        class="form-control">
                    </div>
                   
                    
                    
                    <div class="col-md-2 col-sm-12">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search" @click="getGiftVouchers()">Search</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="row">
                        <div class="col-md-3 pad-r-0">
                          <div class="">
                            <Datepicker placeholder="Start Date" v-model="startDate" utc autoApply
                              :format="customFormatter"></Datepicker>
                          </div>
    
                        </div>
                        <div class="col-md-3 pad-r-0">
                          <div class="">
                            <Datepicker placeholder="End Date" v-model="endDate" autoApply utc :format="customFormatter"
                              :minDate="minDate"></Datepicker>
                          </div>
                        </div>
                        <div class="col-md-3 pad-r-0">
                          <select class="form-control" v-model="generationType" @change="getGiftVouchers()">
                            <option value="">--Select  Type---</option>
                            <option value="0">Regular</option>
                            <option value="1">Bulk Generated</option>
                          </select>
                        </div>
                        <!-- <div class="col-md-2">
                          <select class="form-control" v-model="voucherType" @change="getGiftVouchers()">
                            <option value="">--Select Gift Card Type---</option>
                            <option v-for="voucher in vouchers" :key="voucher.external_voucher_id" :value="voucher.external_voucher_id">{{ voucher.voucher_name }}</option>
                          </select>
                        </div> -->
                      
                      </div>
                    </div>
                  </div>

                  <div class="St-menu" v-if="giftcardConfig.approval_required">
                    <div class="status_btn_group"></div>
                    <button :class="[{'active': statusType == ''},'btn-filter mr-1 mt-1']" @click="filterVoucherApproval('','')">
                      <span  >
                        <strong>All</strong>
                      </span>
                    </button>
                    <button :class="[{'active': statusType == 'Pending'},'btn-filter  mr-1 mt-1']" @click="filterVoucherApproval('Pending','0')">
                      <span style="color:#EC850C">
                        <strong>Pending Approval</strong>
                      </span>
                    </button>
                    <button :class="[{'active': statusType == 'Approved'},'btn-filter mr-1 mt-1']" @click="filterVoucherApproval('Approved','1')">
                      <span style="color:#1B8B20">
                        <strong>Approved</strong>
                      </span>
                    </button>
                    <button :class="[{'active': statusType == 'Rejected'},'btn-filter mr-1 mt-1']" @click="filterVoucherApproval('Rejected','2')">
                      <span style="color:#A81414;">
                        <strong>Rejected</strong>
                      </span>
                    </button>
                  </div>

                </div>
              </div>
              <hr>
            </div>
            
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="table_section">
                      <table class="table  table-hover" v-if="statusType == ''">
                        <thead>
                          <tr>
                            <th scope="col">Reference</th>
                            <th scope="col">From</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Amount ({{ pesapalConfig.currency_code }})</th>
                            <th scope="col">Balance ({{ pesapalConfig.currency_code }})</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Bulk</th>
                            <th scope="col">Date</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody v-if="!loading">
                         
                          <tr v-for="giftVoucher in giftVouchers" :key="giftVoucher.id">
                            <!-- {{giftVoucher}} -->
                            <td>
                              <span v-if="giftVoucher.voucher_reference ">
                                <router-link
                                  :to="{ name: 'giftcardDetail', params: { id: giftVoucher.voucher_reference } }">
                                  {{ giftVoucher.voucher_reference }}
                                </router-link>
                              </span>
                              <span v-else>
                                N/A
                              </span>
                              
                            </td>
                            <td>
                              <span>{{ giftVoucher.first_name !='' || giftVoucher.last_name !='' ? giftVoucher.first_name + " " + giftVoucher.last_name : 'N/A' }} </span>
                            </td>
                            <td>
  
                              <div>
                                {{ giftVoucher.qty }}
                              </div>
                            </td>
                            <td>
                              {{ giftVoucher.percentage_based ? 'N/A': getTotalAmount(giftVoucher) }}
                            </td>
                            <td>
                              {{ giftVoucher.percentage_based ? 'N/A': formatPrice(giftVoucher.total_balance) }}
                            </td>
                            <td>
                              <div v-if="giftVoucher.payment_status">
                                <span :class="[{'active':giftVoucher.payment_status.toLowerCase()== 'completed'},{'pending':giftVoucher.payment_status.toLowerCase()=='placed'},{'disabled':giftVoucher.payment_status.toLowerCase() =='failed'}]"
                                ><b>{{
                                  giftVoucher.payment_status
                                }}</b></span>
                                <br>
                              </div>
                              <div v-else>
                                {{ giftVoucher.payment_method ? giftVoucher.payment_method : 'N/A' }}
                              </div>
                            </td>
                            <td>
                              <span v-if="giftVoucher.admin_generated">
                                Yes
                              </span>
                              <span v-else>No</span>
                            </td>
                            <td>
                              {{ formatDate(giftVoucher.created_at) }}
                            </td>
                            <td>
                              <div v-if="giftVoucher.voucher_reference">
                                <button type="button" :class="['btn  btn-sm btn-purple mt-2 mr-1',{'disabled_content': user.role.id == 3}]"
                                  @click="print(giftVoucher.voucher_reference, 'order')"><span
                                    v-if="spinner == giftVoucher.voucher_reference"><i
                                    class='fa fa-spinner fa-spin '></i></span> Print</button>
                                <span>
                                  <button
                                    v-if="(giftVoucher.payment_status && giftVoucher.payment_status.toLowerCase() == 'completed' || giftVoucher.payment_status && giftVoucher.payment_status.toLowerCase() == 'manually_completed' && !giftVoucher.admin_generated) || (giftVoucher.admin_generated) "
                                    type="button" @click="bulkSendNotification(giftVoucher.voucher_reference)" :class="['btn btn-success btn-sm mt-2 mr-1',{'disabled_content': user.role.id == 3}]"><span v-if="sendLoading == giftVoucher.voucher_reference "> <i class="fa fa-spinner fa-spin"></i> </span> Send
                                  </button>
                                </span>

                                <span>
                                  <button
                                    v-if="giftVoucher.admin_generated  && giftVoucher.total_assigned > 0 "
                                    type="button" @click="exportGiftCards(giftVoucher.voucher_reference)" :class="['btn custom-orange btn-sm mt-2',{'disabled_content': user.role.id == 3}]"><span v-if="exportLoader == giftVoucher.voucher_reference "> <i class="fa fa-spinner fa-spin"></i> </span> Export
                                  </button>
                                </span>
                              </div>
                              <div v-else>
                                N/A
                              </div>

                              
                            </td>
                          </tr>

                        </tbody>
                        <tfoot v-if="!loading">
                          <tr>
                            <td colspan="9">
                              <div id="pagination" v-if="totalPages > 1">
                                <ul class="showItems">
                                  <li>Show Items:
                                    <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                      <option :value="10">10</option>
                                      <option :value="20">20</option>
                                      <option :value="30">30</option>
                                      <option :value="40">40</option>
                                      <option :value="50">50</option>
                                    </select>
                                  </li>

                                </ul>
                                <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page"
                                  @pagechanged="onPageChange" />

                              </div>

                            </td>
                          </tr>
                        </tfoot>
                      </table>

                      <!-- table for approval of vouchers -->

                      <table class="table  table-hover" v-else>
                        <thead>
                          <tr>
                            <th scope="col">Requested Date</th>
                            <th scope="col">Reference</th>
                            <th scope="col">From</th>
                            <th scope="col">Requested by</th>
                            <th scope="col">Voucher Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Payment Details</th>
                            <th scope="col" v-if="statusType !='Pending'">Date {{ statusType }}</th>
                            <th scope="col" v-if="statusType !='Pending'"> {{ statusType }} By</th>
                            <th scope="col" v-if="statusType =='Rejected'">Remarks</th>
                            <th scope="col" width="20%" v-if="statusType =='Pending'">Action</th>
                          </tr>
                        </thead>
                        <tbody v-if="!loading">
                          <tr v-for="giftVoucher in giftVouchers" :key="giftVoucher.id">
                            <td>
                              {{ formatDate(giftVoucher.created_at,1) }}
                            </td>
                            <td>
                              {{ giftVoucher.voucher_reference ? giftVoucher.voucher_reference : 'N/A' }}
                            </td>
                            <td>
                              <span>{{ giftVoucher.first_name !='' || giftVoucher.last_name !='' ?  giftVoucher.first_name + " " + giftVoucher.last_name : 'N/A' }} </span>
                            </td>
                            <td>
                              {{ giftVoucher.requester }}
                            </td>
                            <td>
                              {{ giftVoucher.voucher_name ? giftVoucher.voucher_name : 'N/A' }}
                            </td>
                            <td>
                              {{ getTotalAmount(giftVoucher) }}
                            </td>
                            <td>
                              {{ giftVoucher.qty }}
                             
                            </td>
                            <td>
                              {{ giftVoucher.p_method ? giftVoucher.p_method : 'N/A' }}
                            </td>
                            <td v-if="statusType !='Pending'">
                              {{ giftVoucher.approved_date ? formatDate(giftVoucher.approved_date,1) : 'N/A' }}
                            </td>
                            <td v-if="statusType !='Pending'">
                              {{ giftVoucher.approver ? giftVoucher.approver :'N/A' }}
                            </td>
                            <td v-if="statusType =='Rejected'">
                              {{ giftVoucher.remarks ? giftVoucher.remarks : 'N/A' }}
                            </td>
                            <td v-if="statusType =='Pending'">
                              <div>
                                <button type="button"  class="btn btn-success btn-sm mr-1 t_btn" @click="approveVoucher(giftVoucher.id)"><span v-if="loader==giftVoucher.id"><i class="fa fa-spinner fa-spin"></i></span> Approve</button>
                                <button type="button" class="btn custon_orange btn-sm t_btn" data-toggle="modal" :data-target="`#reject_${giftVoucher.id}`">Reject</button>

                                <!-- modal for reason -->
                                <div class="modal fade"  :id="`reject_${giftVoucher.id}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                  <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLongTitle">Reject Voucher #{{ giftVoucher.voucher_name }}</h5>
                                        <button type="button" class="close" :id="`closedreject_${giftVoucher.id}`" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <div class="form-group">
                                          <label for="exampleInputPassword1">Reason</label>
                                          <input type="text" class="form-control" v-model="rejectReason">
                                         
                                        </div>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary t_btn" data-dismiss="modal">Dismiss</button>
                                        <button type="button" @click="rejectVoucher(giftVoucher.id)" class="btn custon_orange t_btn" ><span v-if="loader==giftVoucher.id"> <i class="fa fa-spinner fa-spin"></i> </span> Reject</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- end modal -->
                              </div>
                            </td>
                          </tr>

                        </tbody>
                        <tfoot v-if="!loading">
                          <tr>
                            <td :colspan="statusType=='Pending'? '9':statusType =='Rejected' ? '11': '10'">
                              <div id="pagination">
                                <ul class="showItems">
                                  <li>Show Items:
                                    <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                      <option :value="10">10</option>
                                      <option :value="20">20</option>
                                      <option :value="30">30</option>
                                      <option :value="40">40</option>
                                      <option :value="50">50</option>
                                    </select>
                                  </li>

                                </ul>
                                <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page"
                                  @pagechanged="onPageChange" />

                              </div>

                            </td>
                          </tr>
                        </tfoot>
                      </table>



                    </div>
                    <div v-if="loading" class="loading-cover">
                      <div class="loader"> </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      giftVouchers: [],
      loading: false,
      spinner: false,
      page: 1,
      totalPages: 0,
      totalRecords: 0,
      recordsPerPage: this.$store.state.giftCardRecordsPerPage ? this.$store.state.giftCardRecordsPerPage :10,
      enterpageno: '',
      search: '',
      debounce: null,
      generationType: '',
      statusType:'',
      rejectReason: '',
      vapprovalStatus: '',
      startDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      loader:false,
      vouchers:[],
      voucherType:'',
      sendLoading:false,
      approvastatus: {
        'approved': 1,
        'rejected':2,
        'pending': 0
      },
      exportLoader:false
      // appSettingConfigs: this.configs.app_settings
    }
  },
  created: async function () {
    this.getGiftVouchers();
    this.getVoucherTypes();
  },
  methods: {
    filterVoucherApproval(status,approvalstatus){
      this.statusType = status
      this.vapprovalStatus = approvalstatus
      this.getGiftVouchers()
    },
    customFormatter(date) {
            return moment(date).format('YYYY-MM-DD');
    },
    async approveVoucher(id){
      try{
        this.loader = id
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        const url = `${this.dashBaseUrl}/approve-voucher/${id}/${this.merchantID}`
        const resp = await this.axios.post(url,{},config)
        if(resp.status == 200){
          this.loader = false
          toast.success("voucher approved successfully")
          this.getGiftVouchers()
        }
      }catch(error){
        if(error.response.status === 400){
              let errorms = '';
              for(let x=0;x< error.response.data.errors.length;x++){
                errorms = error.response.data.errors[x] + ','
              }
              toast.error(errorms);
              this.loader = false
            }else if(error.response.status === 401){
                    this.$router.push({
                        name: 'login'
                    });
             }
      }
    },
    async rejectVoucher(id){
      try{
        this.loader = id
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        const payload = {
          'reject_remarks':this.rejectReason
        }
        const url = `${this.dashBaseUrl}/reject-voucher/${id}/${this.merchantID}`
        const resp = await this.axios.post(url,payload,config)
        if(resp.status === 200){
          document.getElementById(`closedreject_${id}`).click();
          this.loader = false;
          this.getGiftVouchers()
          toast.success("voucher reject approval was successfully")
          
        }
      }catch(error){
        document.getElementById(`closedreject_${id}`).click();
        if(error.response.status === 400){
              document.getElementById(`closedreject_${id}`).click();
              let errorms = '';
              for(let x=0;x< error.response.data.errors.length;x++){
                errorms = error.response.data.errors[x] + ','
              }
              toast.error(errorms);
              
              this.loader = false
            }else if(error.response.status === 401){
                    this.$router.push({
                        name: 'login'
                    });
             }

      }
        
        


    },
    async exportGiftCards(reference){
      try {
        this.exportLoader = reference
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        let url = `${this.dashBaseUrl}/export-giftcard-data/${reference}`;
        url = new URL(url);
        url.searchParams.set('merchant_id',this.merchantID)
        const response = await this.axios.get(url, config)
        if(response.status === 200){
          this.exportLoader = false
          const giftcardDetails = []
          const giftcards = response.data.data
          for(const giftcard of Object.values(giftcards)){ 
            giftcardDetails.push({
              'Date':this.formatDate(giftcard.created_at),
              'Name':giftcard.title,
              'Code':giftcard.code,
              'Amount':giftcard.amount,
              'Balance':giftcard.balance,
              'Type': giftcard.bulk_generated ? 'Bulk Generated' : 'Regular',
              'Buyer Name':giftcard.customer_first_name +' '+giftcard.customer_last_name,
              'Buyer Email':giftcard.customer_email,
              'Buyer Phone':giftcard.customer_phone,
              'Recipient Name':giftcard.recipient_first_name +' ' +giftcard.recipient_last_name,
              'Recipient Email':giftcard.recipient_email,
              'Recipient Phone':giftcard.recipient_phone,
              'Reference':giftcard.reference,
              'Voucher_url':giftcard.voucher_url
            })
          }
          const fileName = Date.now() +'_giftcards'
          this.exportExcel(giftcardDetails, fileName)
        }
      } catch (error) {
        this.exportLoader = false
        if (error.response &&  error.response.status === 401) {
            this.$router.push({
                name: 'login'
            });
        }
        
      }
      
    },
    async getGiftVouchers() {
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      let url = `${this.dashBaseUrl}/giftvouchers`;
      url = new URL(url);
      url.searchParams.set('size',this.recordsPerPage)
      url.searchParams.set('merchant_id',this.merchantID)
      if(this.search !=''){
        url.searchParams.set('search',this.search)
      }
      if(this.voucherType !=''){
        url.searchParams.set('voucher_type',this.voucherType)
      }
      if(this.generationType !=''){
        url.searchParams.set('generation_type', this.generationType)
      }
      if(this.vapprovalStatus !=''){
        url.searchParams.set('approval_status',this.vapprovalStatus)
      }
      if(this.search ==''){
        url.searchParams.set('page',this.page);
        url.searchParams.set('start_date', moment(this.startDate).format('YYYY-MM-DD'))
        url.searchParams.set('end_date',  moment(this.endDate).format('YYYY-MM-DD') )
      }
      this.loading = true;
      this.axios.get(url, config).then((response) => {
        if (response.status === 200) {
          this.loading = false;
          this.giftVouchers = response.data.data.data;
          this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
          this.totalRecords = response.data.data.total

          // check if the voucher if data return is one
          if(response.data.voucher.length != 0){
            this.$router.push(
              { name: 'redemption', 
              params: { "vouche_id": response.data.voucher.id } 
            })
          }
          // 
        }
      }).catch((err) => {
        console.log('error', err);
      })

    },
    async bulkSendNotification(reference){
      this.sendLoading = reference
      try{
        const config = {
            headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        const url = `${this.dashBaseUrl}/bulksend/${reference}`
        const resp = await this.axios.post(url,{
          'merchant_id': this.merchantID
        }, config)
        if(resp.status === 200){
          this.sendLoading = false;
          toast.success('Notification sent success fully')
        }
      }catch(error){
        this.sendLoading  = false
        // 
        if(error.response && error.response.status === 400){
          toast.error("error on sending notifications")
        }
        else if (error.response &&  error.response.status === 401) {
            this.$router.push({
                name: 'login'
            });
        }
      }
        

    },
    getTotalAmount(voucher){
      if(voucher.total_amount && voucher.total_amount > 0){
        return this.formatPrice(voucher.total_amount)
      }else{
        return this.formatPrice(voucher.vamount * voucher.qty)
      }
    },
    async getVoucherTypes() {
        const config = {
            headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        let url = `${this.dashBaseUrl}/vouchers/${this.merchantID}`
        url = new URL(url);
        this.axios.get(url, config).then((response) => {
            if (response.status === 200 ) {
              this.vouchers = response.data.data;
            }
        }).catch((err) => {
            console.log('error', err);
        });
    },
    onPageChange(page) {
      this.page = page
      this.getGiftVouchers();
    },
    onChangeRecordsPerPage() {
      const payload = {
          "type":"giftcards",
          "size":this.recordsPerPage
      }
      this.$store.commit("setPageLimit",payload)
      this.getGiftVouchers();
      // ds
    },
    
  },
  mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>
